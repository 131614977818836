import {Fragment, useEffect} from "react";
import Text from "../components/global/Text";
import {useSearchParams} from "react-router-dom";

const Support = () => {
  const [ searchParams ] = useSearchParams();

  useEffect(() => {
    const text = searchParams.get('text');

    window.location.href = `https://wa.me/+2347072396527?text=${text}`;
  }, [searchParams]);

  return (
    <Fragment>
      <div className="w-screen h-screen flex items-center justify-center">
        <Text text="Redirecting to WhatsApp..."/>
      </div>
    </Fragment>
  )
}

export default Support;